import React from 'react'
import { FaXing, FaLinkedin } from 'react-icons/fa';
import Wav from '../Wav/Wav';
import "./Footer.scss";
function Footer() {
    return (
        <div className="social">
            <div className='social-icons'>
                <div>
                    <a href='https://www.xing.com/profile/Tarek_Abdulrazak'><FaXing /></a>
                </div>
                <div>
                    <a href='https://www.linkedin.com/in/tarek-abdulrazak-207b5a113/'><FaLinkedin /></a>
                </div>
            </div>
            <Wav />
        </div>
    )
}

export default Footer