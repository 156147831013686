import {Exp, Header , About, Skills, Nav , Background ,Contact} from './components/index';
import "swiper/css/bundle";
import './App.css'

import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Nav />
      <Header/>
      <About/>
      <Exp/>
      <Skills/>
      <Contact/>
      <Footer/>
      <Background/>
    </div>
  );
}

export default App;
