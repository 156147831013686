import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import TarekLogo from "../../assets/images/TAREK_LOGO.png";
import "./Nav.scss";

function Nav() {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="main-header">
      <Container className="navbar-app__container">
        <Row>
          <nav className="navbar__app">
            <Col className="navbar-app__logo" lg={3} sm={6}>
              <img src={TarekLogo} alt="TAREK LOGO" className="tarek-logo" />
            </Col>
            <Col className="navbar-app__links" lg={9} sm={6}>
              <ul className="navbar-app__list">
                {[
                  "startseite",
                  "über mich",
                  "erfahrungen",
                  "fähigkeiten",
                  "kontakt",
                ].map((item) => (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`}>{item}</a>
                  </li>
                ))}
              </ul>
              <div className="app__navbar-menu">
                <HiMenuAlt3 onClick={() => setToggle(true)} />

                {toggle && (
                  <div>
                    <HiX onClick={() => setToggle(false)} />
                    <ul>
                      {[
                        "startseite",
                        "über mich",
                        "erfahrungen",
                        "fähigkeiten",
                        "kontakt",
                      ].map((item) => (
                        <li key={item}>
                          <a href={`#${item}`} onClick={() => setToggle(false)}>
                            {item}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          </nav>
        </Row>
      </Container>
    </div>
  );
}

export default Nav;
