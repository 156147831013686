import React, { useRef } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import Wav from "../Wav/Wav";
import emailjs from '@emailjs/browser';
import emailIcon from "../../assets/images/email.png";
import './Contact.scss'
function Contact() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_efnbivz', 'template_ejn2807', form.current, 'kTACfcDfvg8QSEXGY')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className="contact-app" id="kontakt">
            <Wav />
            <Container className="contact-container">
                <Row>
                    <h2>Kontakt</h2>
                    <h3>
                        Kontaktieren Sie Mich,<br />Wenn Sie Interesse An Meinen Fähigkeiten Haben
                    </h3>
                    <Col md={6} xs={12} className="contact-content">
                        <form ref={form} onSubmit={sendEmail}>
                            <Form.Group className="mb-3" controlId="formUserName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="user_name" className='contact-input' />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="user_email" className='contact-input' />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control name="message" className='contact-textarea' />
                            </Form.Group>
                            <Form.Control type="submit" value="Send" className='contact-submit' />
                        </form>
                    </Col>
                    <Col md={6} xs={12} className="img-content">
                        <img width={350} src={emailIcon} alt="email-icon" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contact