import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tarek1 from "../../assets/images/Tarek100.png";
import reactIcon from "../../assets/images/react.png";
import sassIcon from "../../assets/images/sass.png";
import jsIcon from "../../assets/images/js.png";
import "./Header.scss";
import Wav from "../Wav/Wav";
function Header() {
  return (
    <div className="header-app" id="startseite">
      <Container className="header-app">
        <Row>
          <header>
            <Col>
              <Row className="header-content">
                <Col md={3}>
                  <h2>
                    Hallo,{" "}
                    <span>
                      ich bin <span className="tarek">Tarek</span>
                    </span>
                  </h2>
                  <h3>WEB FRONTEND ENTWICKLER</h3>
                </Col>
                <Col md={7}>
                  <img src={tarek1} alt="tarek" />
                </Col>
                <Col md={2}>
                  <div className="header-icons">
                    <img src={reactIcon} alt="react-icon" />
                    <img src={sassIcon} alt="sass-icon" />
                    <img src={jsIcon} alt="js-icon" />
                  </div>
                </Col>
              </Row>
            </Col>
          </header>
        </Row>
      </Container>
      <Wav />
    </div>
  );
}

export default Header;
