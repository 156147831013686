import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import './Exp.scss'
import Wav from "../Wav/Wav";

import BootstrapIcon from "../../assets/images/Bootstrap.png";
import JavascriptIcon from "../../assets/images/javascript.png";
import HtlmlIcon from "../../assets/images/html.png";
import CssIcon from "../../assets/images/css.png";
import SassIcon from "../../assets/images/sass.png";
import ReactIcon from "../../assets/images/react.png";
import PhotoshopIcon from "../../assets/images/photoshop.png";
import IllusIcon from "../../assets/images/illus.png";
import XdIcon from "../../assets/images/xd.png";

function Exp() {
  const data = [
    {
      id: 1,
      title: "HTML",
      imageUrl: HtlmlIcon,
    },
    {
      id: 2,
      title: "CSS",
      imageUrl: CssIcon,
    },
    {
      id: 3,
      title: "JAVASCRIPT",
      imageUrl: JavascriptIcon,
    },
    {
      id: 4,
      title: "REACT JS",
      imageUrl: ReactIcon,
    },
    {
      id: 5,
      title: "BOOTSTRAP",
      imageUrl: BootstrapIcon,
    },
    {
      id: 6,
      title: "SASS",
      imageUrl: SassIcon,
    },
    {
      id: 7,
      title: "PHOTOSHOP",
      imageUrl: PhotoshopIcon,
    },
    {
      id: 8,
      title: "ILLUSTRATOR",
      imageUrl: IllusIcon,
    },
    {
      id: 9,
      title: "XD",
      imageUrl: XdIcon,
    }

  ];
  const dataExp = [
    {
      id: 1,
      date: "2018-2022",
      title: "Ausbildung zum Fachinformatiker bei der IHK",
      desc: "Berufskolleg Technik Remscheid / invokable GmbH",
      type: "Abschluss: Fachinformatik für Anwendungsentwicklung"
    },
    {
      id: 2,
      date: "2017-2018",
      title: "Weiterbildung bei CPI Consulting, Bochum",
      desc: "CPI Consulting",
      type: "Abschluss: Fachkraft Multimedia – Web Design"
    }

  ];
  return (
    <div className="exp-app" id="erfahrungen">
      <Wav />
      <Container className="exp-container">
        <Row>
          <h2>Fähigkeiten & Erfahrungen</h2>
          <h3>Ich freue mich darauf,<br />moderne Webtechnologie zu lernen</h3>
          <Col md={6} xs={12}>
            <Row>
              {data.map((item) => (
                <Col md={4} xs={6} key={item.id} className="exp-content">
                  <figure>
                    <img src={item.imageUrl} alt={item.title} className="exp-thum" />
                    <figcaption>
                      <div className="text-content">
                        <h5>{item.title}</h5>
                      </div>
                    </figcaption>
                  </figure>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={6} xs={12}>
            {dataExp.map((item) => (
              <figure key={item.id}>
                <figcaption className="exp-figcaption">
                  <div className="text-content-er">
                    <h4 className="exp-type">{item.date}</h4>
                    <h4 className='exp-title'>{item.title}</h4>
                    <p>{item.desc}</p>
                    <span>{item.type}</span>
                  </div>
                </figcaption>
              </figure>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Exp