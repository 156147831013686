import React from "react";
import "./Background.scss";
import im1 from "../../assets/images/element1.png";
import im2 from "../../assets/images/element2.png";
import im3 from "../../assets/images/element3.png";
import im4 from "../../assets/images/element4.png";
import im5 from "../../assets/images/element5.png";

function Background() {
  return (
    <div className="site-background">
      <div className="layer-wrapper">
        <div className="layer1">
          <div className="element1">
            <img src={im1} alt="ele1" />
          </div>
          <div className="element2">
            <img src={im2} alt="ele2" />
          </div>
          <div className="element3">
            <img src={im3} alt="ele3" />
          </div>
        </div>
        <div className="layer2">
          <div className="element4">
            <img src={im4} alt="ele4" />
          </div>
          <div className="element5">
            <img src={im5} alt="ele5" />
          </div>
          <div className="element4">
            <img src={im4} alt="ele6" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Background;
