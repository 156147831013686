import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { ReactComponent as EcommerceIcon } from "../../assets/images/ecommerce.svg";
import { ReactComponent as WebGrey } from "../../assets/images/corporatebranding.svg";
import { ReactComponent as CmsDevelopment } from "../../assets/images/cms_development.svg";
import "./About.scss";
function About() {
  const data = [
    {
      id: 1,
      title: "Web Entwicklung",
      desc: "Eine beeindruckende Website mit Benutzeroptimierung und einer intelligenten Benutzeroberfläche ist eine Säule des Erfolgs.",
      imageUrl: <WebGrey className="about-icon" />,
    },
    {
      id: 2,
      title: "Ecommerce Webseite",
      desc: "Die Ambitionen in die Realität umsetzen, indem mit der modernen Technologie Schritt halten und umfassendes strategisch und technisch einsetzen.",
      imageUrl: <EcommerceIcon className="about-icon" />,
    },
    {
      id: 3,
      title: "Web Applikationen",
      desc: "Ich kenne die Kunst des Ausbalancierens und des innovativen Designs, das optisch ansprechend aussieht und die Interaktionsrate maximiert.",
      imageUrl: <CmsDevelopment className="about-icon" />,
    },
  ];
  return (
    <div className="about-app" id="über mich">

      <Container className="about-container">
        <Row>
          <h2>Über Mich</h2>
          <h3>
            Ich Kenne Dieses Gute Design
            <br />
            Bedeutet Ein Gutes Geschäft
          </h3>
          {data.map((item) => (
            <Col md={4} key={item.id} className="about-content">
              <figure>
                {item.imageUrl}
                <figcaption>
                  <div className="text-content">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </figcaption>
              </figure>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default About;
