import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Wav from "../Wav/Wav";
import './Skills.scss'
import url1 from "../../assets/images/tarekabdulrazak.jpg";
import url2 from "../../assets/images/arabox.jpg";
import url3 from "../../assets/images/alcham.png";
import url4 from "../../assets/images/dealsoutlet.jpg";
function Skills() {
  const data = [
    {
      id: 1,
      title: "REACT JS PROJEKT",
      desc: "Ein Trainingsprojekt zur Anwendung der über React JS gelernten Punkte",
      type: "React JS",
      imageUrl: url1,
      webUrl: "https://www.tarekabdulrazak.com/"
    },
    {
      id: 2,
      title: "ARABOX TV SHOP",
      desc: "Online-Shop für TV-Receiver und Zubehör",
      type: "Wordpress",
      imageUrl: url2,
      webUrl: "https://www.araboxtv.com/"
    },
    {
      id: 3,
      title: "ALCHAAM SHOP",
      desc: "Molasse Online-Shop mit Zahlungs- und Versandoptionen",
      type: "Wordpress",
      imageUrl: url3,
      webUrl: "https://alchaam.com/"
    },
    {
      id: 4,
      title: "Deals Outlet",
      desc: "Discounter Shop In USA",
      type: "Wordpress",
      imageUrl: url4,
      webUrl: "https://dealsoutlet.net/"
    },
  ];
  return (
    <div className="skills-app" id="fähigkeiten">
      <Wav />
      <Container className="skills-container">
        <Row>
          <h2>Mein Kreatives Portfolio</h2>
          {data.map((item) => (
            <Col md={3} key={item.id} className="skills-content">
              <a href={item.webUrl} className="skills-link" target="_blank" rel="noreferrer">
                <figure>
                  <img src={item.imageUrl} alt={item.title} className="skills-thum" />
                  <figcaption>
                    <div className="text-content">
                      <h3>{item.title}</h3>
                      <p>{item.desc}</p>
                      <span className="skills-type">{item.type}</span>
                    </div>
                  </figcaption>
                </figure>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Skills