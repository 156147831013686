import React from "react";
import Wave from "react-wavify";
import styled from "@emotion/styled";
import "./Wav.scss";

const WaveContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: -5px;
  height: ${(props) => props.level + "vh"};
  display: flex;
  z-index: -1;
  transform: rotate(180deg);
`;

function Wav() {
  return (
    <div className="wav-container">
      <WaveContainer level={20} className="wavi">
        <Wave
          fill="#ff0032"
          paused={false}
          opacity="0.30"
          options={{
            height: 20,
            amplitude: 20,
            speed: 0.2,
            points: 5,
          }}
        />
      </WaveContainer>
      <WaveContainer level={20}>
        <Wave
          fill="#ff0032"
          opacity="0.80"
          paused={false}
          options={{
            height: 75,
            amplitude: 20,
            speed: 0.3,
            points: 2,
          }}
        />
      </WaveContainer>
      <WaveContainer level={20}>
        <Wave
          fill="#ff0032"
          paused={false}
          opacity="0.5"
          options={{
            height: 45,
            amplitude: 30,
            speed: 0.1,
            points: 4,
          }}
        />
      </WaveContainer>
    </div>
  );
}

export default Wav;
